.dotted-line {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
}

.dotted-line::after {
  content: "";
  flex-grow: 1;
  border-bottom: 1px dotted #000;
  margin-left: 8px;
}
div[draggable="true"] {
  cursor: grab;
}

div[draggable="true"]:active {
  cursor: grabbing;
}

.button-container {
  display: flex;
  margin-left: 25%;
}

.custom-button {
  /* width: 30%;  */
  margin-right: 14px;
  background: #e7e4ee;
  border-color: #e7e4ee;
  display: flex;
  justify-content: "center";
  align-items: "center";
}

.clicked {
  background: #2a29cc;
  border-color: #2a29cc;
}

.custom-button:not(.clicked):hover {
  background: grey;
}
