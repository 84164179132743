.steps-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 0px 0 0 10px;
    height: 40.83dvh;
  }
  
.route-container {
  width: '243px';
  height: '63px'; 
  color: 'black'; 
  border-radius: '8px';
  top: '520px';
  left: '38px';
  margin-bottom:'6px'; 
  background-color: '#D9D9D9'; 
  display: 'flex';
  align-items: 'center'; 
  justify-content: 'center';
  margin-left: '7%';
  margin-top: '1.5%';
}
  .step {
    /* No changes needed */
  }
  .arrow {
    
    color: black;/* Add this line to make the arrow bold */
    /* Other CSS properties for arrow styling */
  }
  .step-details {
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    width: 100%;
  }
  .steps-list {
    /* max-height: 180px; */
    overflow-y: auto;
    width: 100%
  }

  .steps-list::-webkit-scrollbar {
    width: 3px; /* Set the width of the scrollbar */
  }
  
  .steps-list::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the scrollbar thumb */
    border-radius: 3px; /* Round the corners of the thumb */
  }
  
  /* Firefox scrollbar customization */
  /* .steps-list {
    scrollbar-width: thin; 
    scrollbar-color: #888 transparent;
  }
     */
  .step {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }
  .place {
    /* font-weight: bold; */
    text-align: left;
    padding-right: 8px;
  }
  
  .arrow {
    font-size: 24px;
    padding: 0 8px;
    font-weight: bold;
  }
  
  .distance {
    font-size: 12px;
    color: gray;
    margin-left: auto; 
    text-align: right;/* Align the distance to the very right */
    padding-right: 10px;
    /* margin-left: 80px; */
  }
  